import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import Video from '../../components/Video';
import SocialLink from '../../components/SocialLink';
import Team from '../../components/people';

const Index = ({ data }) => {
  const hackathonTeam = data.allPeopleJson.edges.map(item => item.node);
  const { html, frontmatter: { title } } = data.file.childMarkdownRemark;

  return (
    <Layout bodyClass="page-hackathon">
      <SEO title="Hackathon" />
      <div className="container team">
        <div className="intro">
          <div className="row">
            <div className="col-12">
              <h1>Challenge</h1>
              <p>
                Thanks to technology, the world has become more connected than ever before. Almost everyone has a social media account. Despite all of that, transferring money between people is still a challenge - even more so across borders.
              </p>
              <p>
                Even with the rise of Bitcoin and Blockchain, which were created to ease the pain, not everyone uses Bitcoin and its underlying technology nor trusts it.
              </p>
              <p>
                {"We believe that transferring money should be as easy as liking an Instagram post - let's turn social media into the payment channel of the modern age."}
              </p>
              <p>
                {"We invite you to develop a solution which allows everyone on the internet to send and receive money seamlessly domestically and internationally. Let's make money transfer and payments easier than ever!"}
              </p>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <Video
              videoSrcURL="https://www.youtube-nocookie.com/embed/zhU0PaQYRQw"
              videoTitle="Mbanq Challenge"
            />
          </div>
        </div>
        <div className="col-12 pt-4 pb-4 text-center">
          <h1>
            Hackathon Team
          </h1>
        </div>
        <Team people={ hackathonTeam } />
        <div className="col-12 pt-4 pb-4 text-center">
          <h1>
            Ask Your Questions
          </h1>
          <p>
            Please, ask any questions related to the challenge in our telgram group
          </p>
          <h1>
            <SocialLink
              icon="telegram"
              name="Take me to the telegram group"
              url="https://t.me/MbanqAtF10Hackathon"
            />
          </h1>
        </div>
        <div className="col-12 pt-4 pb-4 text-center">
          <h1>
            { title }
          </h1>
        </div>
        <div className="container pt-8 pt-md-10">
          <div className="row justify-content-start">
            <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </div>

    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query {
    allPeopleJson(filter: {teams: {in: ["hackathon"]}}) {
      edges {
        node {
          id
          name
          image
          jobtitle
          email
          company {
            name
            url
          }
          description
          linkedinurl
        }
      }
    }
    file(name: {eq: "HowTo"}) {
      childMarkdownRemark {
        frontmatter {
          title
        }
        html
      }
    }
  }
`;

export default Index;
