import React from 'react';
import PropTypes from 'prop-types';

const Video = ({ videoSrcURL, videoTitle, dimensions, ...props }) => {
  return (
    <div className="video">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        width={dimensions.width}
        height={dimensions.height}
      />
    </div>
  );
};

Video.defaultProps = {
  dimensions: {
    width: 640,
    height: 320,
  },
};

Video.propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  dimensions: PropTypes.objectOf(PropTypes.number),
};

export default Video;
